import React, { FC, useEffect, useState } from 'react';
import { images } from '../../../../commonConfig';
import './view-detail-modal.scss';
import { VscChromeClose } from 'react-icons/vsc'
import { db } from '../../../../firebase/firebase';

type ViewDetailsProps = {
    data: any;
    screen: 'mobile' | 'desktop';
    resData: any
}
const ViewDetails: FC<ViewDetailsProps> = ({ data, screen, resData }) => {
    const [showDetails, setShowDetails] = useState(false)
    const [recommendationsItems, setRecommendationsItems] = useState<any>([])
    let addOns = [];
    let sizes = [];
    let options = [];
    let recommendations: any = [];
    // let customModifiers = [];

    useEffect(() => {
        getCategoryItems()
    }, [])

    const getCategoryItems = async () => {
        console.log(resData)
        try {
            const categoriesRef = db.collection(`Restaurant/${resData?.resId ?? resData?.resData?.id}/Category`).where('displaycategoryPreOrder', '==', true);
            const snapshot = await categoriesRef.get();

            const categories = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            const sortedCategories = categories.sort((a: any, b: any) => a.categoryIndex - b.categoryIndex);
            const categoryNames = sortedCategories.map((category: any) => category.name);

            const itemsRef = db.collection(`Restaurant/${resData?.resId ?? resData?.resData?.id}/Items`)
            const itemsSnapshot = await itemsRef.where('category_tag', 'array-contains-any', categoryNames).get();
            const items = itemsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            const filteredItems = items.filter((item: any) => {
                return (item.sold_out === false || item.sold_out === undefined) &&
                    (item.item_status === false || item.item_status === undefined);
            })
            setRecommendationsItems(filteredItems || [])
        } catch (error) {
            console.error("Error fetching items: ", error);
        }
    }

    if (data.modifiers) {
        if (data.modifiers?.addOns.length !== 0) {
            addOns = data.modifiers?.addOns;
        }
        if (data.modifiers?.sizes.length !== 0) {
            sizes = data.modifiers?.sizes;
        }
        if (data.modifiers?.options.length !== 0) {
            options = data.modifiers?.options;
        }
        if (data?.modifiers?.recommendationsModifiers?.length) {
            const sortedCategories = data?.modifiers?.recommendationsModifiers?.sort((a: any, b: any) => a.categoryIndex - b.categoryIndex);
            const mergedItems = sortedCategories.reduce((accumulator: any, currentCategory: any) => {
                return accumulator.concat(currentCategory.items);
            }, []);
            recommendations = mergedItems || []
        }
        // if (data.modifiers?.customModifiers) {
        //     customModifiers = data.modifiers?.customModifiers;
        // }
    }

    return (<>
        {(addOns?.length > 0 || sizes?.length > 0 || options?.length > 0 || data.spice_level || data.prep_time || data.modifiers?.customModifiers || recommendations?.length || recommendationsItems?.length)
            ? <>
                <div onClick={() => setShowDetails(true)} className={screen === 'desktop' ? "view-details-desktop" : 'view-details'}>
                    <span>View Details
                    </span>
                </div>

                {showDetails && <>
                    <div className="modal-main-content show">
                        <div className='border-top-mobile'></div>
                        <div className="viewDetails-header">
                            <div onClick={() => setShowDetails(false)} className="btn-modal close-button button" style={{ marginTop: "0px" }}> <VscChromeClose /></div>
                        </div>
                        <div className='border-left'></div>
                        <div className='view-details-container'>
                            <div className='view-details-subcontainer'>
                                {(data?.spice_level || options?.length > 0 || data?.modifiers?.customModifiers || recommendations?.length || recommendationsItems?.length)
                                    ?
                                    <div className='size-add-on-spice'>
                                        <div className='wing-sauce-container'>
                                            {options.length > 0 && <div className='view-details-heading' style={{ textAlign: "left" }}>Options</div>}
                                            {options.map((ele: any) => (<p style={{ textAlign: "left" }}>{ele.option}</p>))}
                                        </div>
                                        <div className="view-details-subheading">
                                            {/* {data?.modifiers?.customModifiers && <div className='view-details-heading' style={{ textAlign: "left" }}></div>} */}
                                            {data?.modifiers?.customModifiers?.map((customModifier: any) => (
                                                <div className='modifier-items-main-container'>
                                                    <div key={customModifier?.modifierName} className='sizes-container-custom' style={{ justifyContent: "left", gap: "15px" }}>
                                                        <div className='view-details-heading' style={{ textAlign: "left" }}>
                                                            {customModifier?.modifierName}
                                                        </div>
                                                        <div className='modifier-items-container'>
                                                            {customModifier?.modifierItems?.map((item: any) => (
                                                                <div key={item.modifyIndex} className='modifier-item'>
                                                                    <p style={{ textAlign: "left" }}>{item.item}</p>
                                                                    {item.price && <p style={{ textAlign: "left", color: "#546b6b", fontWeight: "400" }}>${item?.price}.00</p>}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {recommendations?.length ?
                                            <div className='recommendations-container'>
                                                <span className='recommendations-title'>RECOMMENDATIONS</span>
                                                <div className='recommendations-list'>
                                                    {
                                                        recommendations.map((recommendation: any) => {
                                                            return (
                                                                <div className='recommendation-content'>
                                                                    <span className='recommendation-name'>{recommendation?.name}</span>
                                                                    <span className='recommendation-price'>${recommendation?.price}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className='recommendations-container'>
                                                <span className='recommendations-title'>RECOMMENDATIONS</span>
                                                <div className='recommendations-list'>
                                                    {
                                                        recommendationsItems.map((recommendation: any) => {
                                                            return (
                                                                <div className='recommendation-content'>
                                                                    <span className='recommendation-name'>{recommendation?.name}</span>
                                                                    <span className='recommendation-price'>${recommendation?.price}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div style={{ marginBottom: "-5px", marginTop: '20px' }}>
                                            {data.spice_level > 0 && <div className='view-details-heading spice-heading'>Spice Level </div>}
                                            {data.spice_level > 0 && <div className="spice-container">
                                                <button className="button spicy-icon" >
                                                    <div style={{ color: '#01a8ac', display: "flex", gap: "8px" }} >
                                                        <img src={data.spice_level > 0 ? images.IMG_SPICE_COLOR : images.IMG_SPICE} className="img_spice" />
                                                        <img src={data.spice_level > 1 ? images.IMG_SPICE_COLOR : images.IMG_SPICE} className="img_spice" />
                                                        <img src={data.spice_level > 2 ? images.IMG_SPICE_COLOR : images.IMG_SPICE} className="img_spice" />
                                                        <img src={data.spice_level > 3 ? images.IMG_SPICE_COLOR : images.IMG_SPICE} className="img_spice" />
                                                    </div>
                                                </button>
                                                <div className="spice-text">
                                                    {data.spice_level > 3 ? "Extra hot" : data.spice_level > 2 ? "Hot" : data.spice_level > 1 ? "Medium " : "Mild"}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    : ''}
                                {(sizes?.length > 0 || addOns?.length > 0 || data.prep_time > 0)
                                    ? <div style={{ display: "flex" }}>
                                        <div className='size-add-on'>
                                            <div>
                                                <div className="view-details-subheading">
                                                    {sizes.length > 0 && <div className='view-details-heading'>Sizes</div>}
                                                    {sizes?.map((ele: any) => (
                                                        <div className='sizes-container'>
                                                            <div className='view-detail-name-heading' >
                                                                <p>{ele.size}</p>
                                                            </div>
                                                            <div className='view-details-price'>
                                                                <p>${ele.price}.00</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="view-details-subheading"> {addOns.length > 0 && <div className='view-details-heading'>Add Ons</div>}
                                                    {addOns.map((ele: any) => (
                                                        <div className='sizes-container'>
                                                            <div className='view-detail-name-heading' >
                                                                <p>{ele.item}</p>
                                                            </div>
                                                            <div className='view-details-price'>
                                                                <p>${ele.price}.00</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {data.prep_time && <div style={{ marginBottom: "24px" }}>
                                                <div className='view-details-heading'>   Prep time </div>
                                                <p className='prep-time'>{data.prep_time} minutes</p>
                                            </div>}
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </>}</>
            : ""}
    </>)
}
export default ViewDetails;
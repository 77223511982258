import { FC, useEffect, useRef, useState } from "react";
import "./MenuItemTab.scss";
import { Carousel } from "antd";
import { images } from "../../../commonConfig/index";
import ViewDetails from "../../molecules/Menu-Item/view-detail-modal/view-detail-modal";
import { taxProps } from "../../../interfaces";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { db } from "../../../firebase/firebase";
type Item = {
  item: any;
  index: any;
  data: any;
  taxProps: taxProps;
  sponsorData: any;
  menuFeedbackData?: any
};

const MenuItemTab: FC<Item> = ({
  item,
  index,
  data,
  taxProps,
  sponsorData,
  menuFeedbackData
}) => {
  const [currentItem, setCurrentItem] = useState(1);
  const [selectedSub, setSelectedSub] = useState<any>();
  const [subCategoryItems, setSubCategoryItems] = useState<any[]>([]);
  const CarouselRef = useRef<any>();
  const { localTax, serviceCharge } = taxProps;
  const handleNext = () => {
    if (currentItem < totalItem) {
      CarouselRef.current.next();
      setCurrentItem(currentItem + 1);
    }
  };

  const handlePrev = () => {
    if (currentItem != 1) {
      CarouselRef.current.prev();
      setCurrentItem(currentItem - 1);
    }
  };

  const itemData = subCategoryItems?.filter((obj: any) =>
    obj.subCategories?.includes(item.name)
  );

  const mergedItemAds = itemData?.map((data: any) => {
    sponsorData?.map((ads: any) =>
      ads.item_name === data.name
        ? (data = {
          ...data,
          itemSponsorBannerUrl: ads.sponser_media,
          sponser_url: ads.sponser_url,
        })
        : ""
    );
    return data;
  });

  let totalItem = 0;

  if (item?.itemCoverImageUrl) {
    totalItem += 1;
  }
  if (item?.itemVideoUrl) {
    totalItem += 1;
  }
  if (item?.itemSponsorBannerUrl) {
    totalItem += 1;
  }
  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getSubCategoryItemsList = async () => {
    const subCategory: any[] = [];
    await db
      .collection("Restaurant")
      .doc(data.resId)
      .collection("Items")
      .where("item_status", "==", false)
      .orderBy("menuIndex")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          subCategory.push(doc.data());
        });
      });
    var mergedArray = subCategory.map((item) => {
      var matchingItem = menuFeedbackData.find((element: any) => element.id === item.id);
      return { ...item, ...matchingItem };
    });
    setSubCategoryItems(mergedArray);
  };

  useEffect(() => {
    getSubCategoryItemsList();
  }, []);

  return (
    <>
      {item.description && (
        <p className="main-subcategory-text">{item.description} </p>
      )}
      {(localTax || serviceCharge) && (
        <div className="main-subcategory-subtext-menu">
          <span>
            FINAL PRICE WILL INCLUDE: {localTax && localTax + "% TAX "}
            {localTax && serviceCharge && "+ "}
            {serviceCharge && serviceCharge + "% SERVICE CHARGE"}
          </span>
        </div>
      )}
      <div className="menu-item" id={index}>
        <div className="border-menu" style={{ color: "#9cdde0" }}></div>
        <div className="menu-header-section">
          <div className="menu-header-details">
            {/* <div className='menu-header-type'>
                            <div className='tag-name'>
                                {item.menu_type} Menu
                            </div>
                            <span className='no-content'> <GoDotFill size={8} style={{ marginBottom: "7px" }} /> </span>
                            <div className='category-name'>
                                {item.category_tag}
                            </div>
                        </div> */}
            <div className="res-name-header">{item.name}</div>
          </div>
          <div className="menu-header-logo">
            <img
              src={
                data.resData.companyLogoImage
                  ? data.resData.companyLogoImage
                  : images.IMG_DEFAULT_LOGO
              }
              alt="icon"
              className="logo-image"
            />
          </div>
        </div>
        <div className="border-3"></div>
        <div className="menu-body-section">
          <div style={{ width: "50%", position: "relative" }}>
            {item?.category && itemData.length !== 0 ? (
              <div>
                <Carousel dots={true} ref={CarouselRef} infinite={false}>
                  {
                    <div>
                      <div className="soldOut-image-container">
                        <img
                          src={
                            selectedSub?.itemCoverImageUrl !== ""
                              ? selectedSub?.itemCoverImageUrl
                              : data?.resData.companyLogoImage
                                ? data?.resData.companyLogoImage : images.IMG_DEFAULT_LOGO
                          }
                          alt="icon"
                          className="body-image"
                        />
                        {selectedSub?.sold_out && (
                          <div className="sold-out-text" style={{ top: "0px" }}>
                            {selectedSub?.sold_out && "SOLD OUT"}
                          </div>
                        )}
                      </div>
                    </div>
                  }
                  {selectedSub?.itemVideoUrl && (
                    <div>
                      <video
                        src={selectedSub.itemVideoUrl}
                        controls={true}
                        className="body-image"
                        autoPlay={false}
                      />
                    </div>
                  )}
                  {selectedSub?.itemSponsorBannerUrl && (
                    <div>
                      <a href={selectedSub.sponser_url} target="_blank">
                        <img
                          src={selectedSub.itemSponsorBannerUrl}
                          alt="icon"
                          className="body-image"
                        />
                      </a>
                    </div>
                  )}
                </Carousel>
                {/* {((selectedSub?.itemSponsorBannerUrl &&
                  selectedSub?.itemVideoUrl &&
                  selectedSub?.itemCoverImageUrl) ||
                  (selectedSub?.itemSponsorBannerUrl &&
                    selectedSub?.itemVideoUrl) ||
                  (selectedSub?.itemVideoUrl &&
                    selectedSub?.itemCoverImageUrl) ||
                  (selectedSub?.itemCoverImageUrl &&
                    selectedSub?.itemSponsorBannerUrl)) && (
                  <div className="carousel-icon-class">
                    <div className="numbertext">
                      <div> {currentItem + "/" + totalItem}</div>
                    </div>
                    <AiOutlineLeft
                      className="icon-right"
                      onClick={handlePrev}
                      style={currentItem === 1 ? { color: "#f4f6f6" } : {}}
                    />
                    <AiOutlineRight
                      onClick={handleNext}
                      className="icon-right"
                      style={
                        currentItem === totalItem ? { color: "#f4f6f6" } : {}
                      }
                    />
                  </div>
                )} */}
              </div>
            ) : (
              <div>
                <Carousel dots={true} ref={CarouselRef} infinite={false}>
                  {item?.itemCoverImageUrl || item?.coverImageURL ? (
                    <div className="soldOut-image-container">
                      {item?.itemCoverImageUrl && (
                        <img
                          src={item.itemCoverImageUrl}
                          alt="icon"
                          className="body-image"
                        />
                      )}
                      {item?.coverImageURL && (
                        <img
                          src={item?.coverImageURL}
                          alt="icon"
                          className="body-image"
                        />
                      )}
                      {item.sold_out && (
                        <div className="sold-out-text" style={{ top: "0px" }}>
                          {item.sold_out && "SOLD OUT"}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {data.resData.companyLogoImage ? (
                        <div>
                          <div className="soldOut-image-container">
                            <img
                              src={data.resData.companyLogoImage}
                              alt="icon"
                              className="body-image"
                            />
                            {item.sold_out && (
                              <div
                                className="sold-out-text"
                                style={{ top: "0px" }}
                              >
                                {item.sold_out && "SOLD OUT"}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="soldOut-image-container">
                            <img
                              src={images.IMG_DEFAULT_LOGO}
                              alt="icon"
                              className="body-image"
                            />
                            {item.sold_out && (
                              <div
                                className="sold-out-text"
                                style={{ top: "0px" }}
                              >
                                {item.sold_out && "SOLD OUT"}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {item?.itemVideoUrl && (
                    <div>
                      <video
                        src={item.itemVideoUrl}
                        controls={true}
                        className="body-image"
                        autoPlay={false}
                      />
                    </div>
                  )}
                  {item?.itemSponsorBannerUrl && (
                    <div>
                      <a href={item.sponser_url} target="_blank">
                        <img
                          src={item.itemSponsorBannerUrl}
                          alt="icon"
                          className="body-image"
                        />
                      </a>
                    </div>
                  )}
                </Carousel>
              </div>
            )}
          </div>
          <div className="menu-body-details-container">
            {item?.category && mergedItemAds?.length !== 0 && (
              <div className="menu-item-slider">
                <Slider {...settings}>
                  {mergedItemAds?.map((ele: any, index: any) => {
                    if (!selectedSub) {
                      setSelectedSub(mergedItemAds[0]);
                    }
                    return (
                      <div key={item.id} onClick={() => setSelectedSub(ele)}>
                        <div
                          className="menu-body-text"
                          style={
                            selectedSub?.name === ele?.name
                              ? { borderBottom: "2px solid" }
                              : {}
                          }
                        >
                          {ele.name}
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            )}
            <div className="menu-body-details">
              <div className="body-item-container">
                <h1 className="body-item-name">{item.name}</h1>
                {item?.category && selectedSub ? (
                  <>
                    <p className="body-item-text">{selectedSub?.name}</p>
                    <p className="body-item-desc">{selectedSub?.description}</p>
                  </>
                ) : (
                  <p className="body-item-desc">{item.description}</p>
                )}
                {item.price ? (
                  <div className="body-item-checkout" style={{ alignItems: 'center', display: 'flex', height: '30px' }}>
                    <span className="name-price">
                      ${item.price} {data.resData.currency}
                    </span>
                    <div style={{ height: '20px', border: '1px solid #E2E9E9' }}></div>
                    <div style={{ display: 'flex', marginLeft: '10px', alignItems: 'center' }}>
                      <span style={{ color: '#026669', fontSize: '16px', fontWeight: '600', fontFamily: 'Poppins' }}>Prep time :</span>
                      {item?.prep_time && <span style={{ color: '#026669', fontSize: '16px', fontWeight: '400', fontFamily: 'Poppins', marginLeft: '5px' }}>{item?.prep_time} min</span>}
                    </div>
                  </div>
                ) : (
                  <div className="body-item-checkout" style={{ alignItems: 'center', display: 'flex', height: '30px' }}>
                    <span className="name-price">
                      {selectedSub?.price &&
                        `$${selectedSub?.price} ${data.resData.currency}`}
                    </span>
                    <div style={{ height: '20px', border: '1px solid #E2E9E9' }}></div>
                    <div style={{ display: 'flex', marginLeft: '10px', alignItems: 'center' }}>
                      <span style={{ color: '#026669', fontSize: '16px', fontWeight: '600', fontFamily: 'Poppins' }}>Prep time :</span>
                      {selectedSub?.prep_time && <span style={{ color: '#026669', fontSize: '16px', fontWeight: '400', fontFamily: 'Poppins', marginLeft: '5px' }}>{selectedSub?.prep_time} min</span>}
                    </div>
                  </div>
                )}
                {/* {item.rate} */}

                {item.rate ? (
                  <>{item.rate ? <div className='feedback-text-homepage' style={{ paddingBottom: "8px" }}>
                    <img src={images.IMG_STAR_DARK_FILLED} className="feedback-star-home" /><div>{item?.rate}</div>
                  </div> : ""}</>
                ) : (
                  <>{selectedSub?.rate ? <div className='feedback-text-homepage'>
                    <img src={images.IMG_STAR_DARK_FILLED} className="feedback-star-home" /><div>{selectedSub?.rate}</div>
                  </div> : ""} </>
                )}

                {/* {selectedSub?.rate ? <div className='feedback-text-homepage'>
                  <img src={images.IMG_STAR_DARK_FILLED} className="feedback-star-home" /><div>{selectedSub?.rate}</div>
                </div> : ""} */}
                <div className="details-dietary">
                  {item?.dietary && item.dietary.length ? item.dietary.map((item: any) => (
                    <div className="dietary-name">{item}</div>
                  )) : selectedSub?.dietary && selectedSub?.dietary.length ? selectedSub?.dietary.map((item: any) => (
                    <div className="dietary-name">{item}</div>
                  )) : ''}
                </div>

              </div>
              {selectedSub ? (
                <ViewDetails data={selectedSub} screen="mobile" resData={data}/>
              ) : (
                <ViewDetails data={item} screen="mobile" resData={data}/>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuItemTab;
